// jQuery Document Ready
var $ = jQuery;
jQuery(document).ready(function($) {
	let resized;
	let _windowWidth = $(window).outerWidth();
	let controller = new ScrollMagic.Controller();

	window.onresize = function () { //check for resize every 100ms on resize event to prevent spamming js
		clearTimeout(resized);
		resized = setTimeout(resizedw, 100);
	}

	function resizeWidthOnly() { //return new window width if different than previous else return false. This makes sure height changes don't trigger resize events
		let currentWindowWidth = $(window).outerWidth();
		if (_windowWidth !== currentWindowWidth) {
			_windowWidth = currentWindowWidth;
			return _windowWidth;
		} else {
			return false;
		}
	}

	function resizedw() {
		if (resizeWidthOnly() !== false) {
			hideAllNavElements();
		}
	}

	function hideAllNavElements() {
		$(".rain-vip-header-nav, .rain-vip-header-hamburger, .rain-vip-header-logo").removeClass("active");
		$("body").removeClass("no-scroll");
	}

	//For fixed nav background
	$(window).scroll(function () {
		setStickyHeader();
	});

	setStickyHeader();
	function setStickyHeader() {
		if ($(document).scrollTop() > 0) {
			$(".rain-vip-header").addClass("active");
		} else {
			$(".rain-vip-header").removeClass("active");
		}
	}

	//Hamburger menu click event
	$("#js-hamburger").click(function (e) {
		e.preventDefault();
		$(this).toggleClass("active");
		$(".rain-vip-header-nav, .rain-vip-header-logo").toggleClass("active");
		$("body").toggleClass("no-scroll");
	});

	//Nav menu click event
	scrollTo("#nav-agenda", ".rain-vip-agenda", true);
	scrollTo("#nav-rsvp, .rain-vip-hero .rain-btn", ".rain-vip-form", true);
	scrollTo("#nav-experience", ".rain-vip-experience", true);
	scrollTo("#nav-your-hosts", ".rain-vip-hosts", true);
	scrollTo("#nav-questions", ".rain-vip-questions", true);
	function scrollTo(trigger, block, togglenav) {
		$(trigger).on('click', function (e) {
			e.preventDefault();
			let headerHeight = $('.rain-vip-header').outerHeight();
			$('html,body').animate({
				scrollTop: $(block).offset().top - headerHeight
			}, 1000);
			if ($(window).width() <= 991 && togglenav) {
				hideAllNavElements();
			}
		});
	}

	/************/
	/* Parallax */
	/************/
	//Parallax background
	[].slice.apply(document.querySelectorAll(".parallax-parent")).forEach(function (el) {
		let opts = {triggerElement: el, triggerHook: "onEnter", duration: "175%"},
			scene = new ScrollMagic.Scene(opts).setTween($(el).find(".parallax-parent-img"), {
				y: "80%",
				ease: Linear.easeNone
			}).addTo(controller);

		$(el).fadeIn();
	});

	//Parallax Copy
	[].slice.apply(document.querySelectorAll(".parallax-copy")).forEach(function (el) {
		let opts = {triggerElement: el, triggerHook: "onEnter", duration: "125%"},
			scene = new ScrollMagic.Scene(opts).setTween(el, {x: "-25%", ease: Linear.easeNone}).addTo(controller);
	});

	//Parallax rotated items
	[].slice.apply(document.querySelectorAll(".parallax-rotated")).forEach(function (el) {
		let opts = {triggerElement: el, triggerHook: "onEnter", duration: "150%"},
			scene = new ScrollMagic.Scene(opts).setTween(el, {y: "-50%", ease: Linear.easeNone}).addTo(controller);
	});

	/***********/
	/* Fade Up */
	/***********/
	[].slice.apply(document.querySelectorAll(".fade-up")).forEach(function (el) {
		let opts = {triggerElement: el, triggerHook: 1, offset: 20},
			scene = new ScrollMagic.Scene(opts).addTo(controller);
		scene.on("start end", function (event) {
			el.classList.add("in-view");
		});
	});

	[].slice.apply(document.querySelectorAll(".fade-up-children")).forEach(function (el) {
		let opts = {triggerElement: el, triggerHook: 1, offset: 20},
			scene = new ScrollMagic.Scene(opts).addTo(controller);
		scene.on("start end", function (event) {
			el.classList.add("in-view");
		});
	});

	// =============================================================
	// = The Experience Swiper  		         =
	// =============================================================
	let experienceSwiperThumb = new Swiper(".rain-vip-experience-swiper-thumbs", {
		spaceBetween: 10,
		slidesPerView: "auto",
		watchSlidesProgress: true,
		navigation: {
			nextEl: ".rain-vip-experience__col-swiper .swiper-button-next",
			prevEl: ".rain-vip-experience__col-swiper .swiper-button-prev",
		},
		breakpoints: {
			768: {
				spaceBetween: 20,
			}
		}
	});

	let experienceSwiper = new Swiper(".rain-vip-experience-swiper", {
		autoHeight: true,
		thumbs: {
			swiper: experienceSwiperThumb,
		},
	});

	experienceSwiper.on('slideChange', function (e) {
		let headerHeight = $('.rain-vip-header').outerHeight();
		$('html,body').animate({
			scrollTop: $(".rain-vip-experience__col-swiper").offset().top - headerHeight
		}, 300);
	});

	// =============================================================
	// = Function to initialize JotForm RSVP Form  		         =
	// =============================================================

	// Validate
	let attendingRuleSet = {
		required: {
			depends: function (element) {
				return $('input[name="attending"]:checked').val() === "Yes";
			}
		}
	}

	let rainRSVPForm = $('#rain-vip-form');
	let formSubmitted = false;
	let optionalFields = $('input[name="phone"], input[name="address-1"], input[name="city"], input[name="state"], input[name="postal"], input[name="rafting"], input[name="arrival"], input[name="departure"], input[name="eac"], input[name="restrictions"], textarea[name="questions"]');

	$('input[name="attending"]').change(function() {
		if (formSubmitted) {
			$('#rain-vip-form').valid();
		}

		if ($(this).val() === "No") {
			optionalFields.attr('disabled','disabled');
		} else {
			optionalFields.removeAttr("disabled");
		}
	});

	rainRSVPForm.submit(function() {
		formSubmitted = true;
	});

	rainRSVPForm.validate({
		errorPlacement: function (label, element) {
			if (element.is(":radio")) {
				$(element).closest(".rain-vip-form-fields").addClass("error");
				label.appendTo($(element).closest(".rain-vip-form-fields"));
			} else {
				$(element).parent().addClass("error");
				label.insertAfter(element);
			}
		},
		success: function (label, element) {
			$(element).parent().removeClass("error");
			label.remove();
		},
		rules: {
			'phone': attendingRuleSet,
			'address-1': attendingRuleSet,
			'city': attendingRuleSet,
			'state': attendingRuleSet,
			'postal': attendingRuleSet,
			'rafting': attendingRuleSet,
			'arrival': attendingRuleSet,
			'departure': attendingRuleSet,
		},
		messages: {
			"attending": "Please select one.",
			"name-first": "First name is required.",
			"name-last": "Last name is required.",
			"email": "Please enter a valid email address.",
			"phone": "Please enter a valid phone number.",
			"company": "Please provide your company name.",
			"rafting": "Please select one.",
			"arrival": "Please select one.",
			"departure": "Please select one.",
		},
		submitHandler: function (form) {
			var postData = $(form).serializeArray();
			//console.log(postData);
			postData.push({name: 'hostname', value: document.location.hostname});

			//Google reCAPTCHA
			grecaptcha.ready(function () {
				grecaptcha.execute('6LfFWSIaAAAAABkwtFshM6P4TnY3rVFlkQoJ94ky', {action: 'jotformSubmit'}).then(function (token) {
					postData.push({name: 'token', value: token});
					postData.push({name: 'action', value: 'jotformSubmit'});
					//console.log(postData);

					$.ajax({
						type: "POST",
						url: "../jotform-rsvp.php",
						data: postData,
						cache: false
					}).done(function (data) {
						optionalFields.removeAttr("disabled");
						//console.log("ajax returned: "+data);
						$('#rain-vip-form input[type=text]').val('');
						$('#rain-vip-form input[type=email]').val('');
						$('#rain-vip-form input[type=tel]').val('');
						$('#rain-vip-form textarea').val('');
						$('#rain-vip-form input[type=checkbox]').prop('checked', false);
						$('#rain-vip-form input[type=radio]').prop('checked', false);

						setTimeout(function(){
							alert('Congratulations - your RSVP has been submitted.');
					  	}, 1000);

						// GTM: dataLayer Push
						pushGTM('contact-form-submit',{
							'action' : 'form-submit',
							'type' : 'full',
							'regarding' : 'rsvp'
						});

					}).fail(function (xhr, textStatus, errorThrown) {
						console.log("ajax failure: " + textStatus + "|" + errorThrown);
					});
				});
			});

		}
	});
});

// ===================================================================
// Global Function to event and properties object to GTM dataLayer
// ===================================================================
function pushGTM(eventName,eventProperties){
	if (typeof window.dataLayer !== 'undefined'){
		if (typeof eventName === 'string' && typeof eventProperties === 'object'){
			eventName = eventName.replace(' ','-').replace('_','-');
			window.dataLayer.push({
				'event': 'JS - '+eventName,
				'js-event': eventProperties
			});
		}
	}
}